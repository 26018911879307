import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './App.css'
import axios from 'axios'
import {Provider} from 'react-redux'
import {createStore, IModuleStore} from 'redux-dynamic-modules'
import {defaultContextValues, ProvidenceContext} from '@opencraft/providence/react-plugin/context'
import {NetCallOptions} from '@opencraft/providence/base/types/NetCallOptions'
import {DeriveSingleArgs} from '@opencraft/providence/base/types/DeriveSingleArgs'
import { DeriveListArgs } from '@opencraft/providence/base/types/DeriveListArgs'
import { PaginationResult } from '@opencraft/providence/base/types/PaginationResult'
import {TabNav} from './components/TabNav'
import {SinglesDemo} from './components/SinglesDemo'
import {ListsDemo} from './components/ListsDemo'
import {FormsDemo} from './components/FormsDemo'
import {ValidatorArgs} from '../../src/base/forms/types/ValidatorArgs'


// To begin using Providence, we need to initialize a dynamic module store:
const store: IModuleStore<{}> = createStore({})

// Next we write some client functions. These are functions that handle requests to, and responses from, our API.
//
// The first of these is 'netCall', a function that performs the HTTP request to the server. For this demo, we will be
// using the service https://reqres.in/, which has a bunch of dummy data endpoints for us to play with.
const netCall = <T, K = T>(options: NetCallOptions<T>): Promise<K> => {
  // You'll want to add whatever other Axios configuration arguments you need for your API here. That will likely
  // include things like Authorization headers. Read the Axios documentation for more information.

  // In our case, we shovel everything into the 'data' key when sending outward, since all of reqres.io's endpoints put
  // the relevant data in the 'data' key of the response. So, we mirror this behavior when sending outward as well.
  if (options.data !== undefined) {
    options = {...options, data: {data: options.data} as unknown as T}
  }
  return axios.request(options)
}

// The next is 'deriveSingle', a function that gets the data for a target object on an endpoint. See the Single module
// documentation for more information.
const deriveSingle = <T,>({response, state}: DeriveSingleArgs<T>): T => {
  // The reqres API will return a full object if we're running a get request-- but if we run patch or post, it will
  // return only what we send it. So, we'll merge the result with what we already have in state. In most real-world
  // APIs, this spread operation won't be necessary-- you'll just return some attribute within the data, or the data
  // directly.
  return {...state.x, ...response.data.data}
}

// After that, we need a function for deriving lists. The reqres API provides the pageInfo in the responses it gives.
const deriveList = <T,>({response, state}: DeriveListArgs<T>): PaginationResult<T> => {
  if (state.paginated) {
    return {
      list: response.data.data,
      pageInfo: {
        count: response.data.total,
        size: response.data.per_page,
      }
    }
  }
  // For completeness's sake, we need to handle non-paginated lists in this function.
  return {
    list: response.data.data,
    pageInfo: {
      count: response.data.data.length,
      size: response.data.data.length,
    }
  }
}

// The Providence redux plugin should give you sane defaults. In most cases, the only thing you need to override
// is netCall.
const buildContext = defaultContextValues()
buildContext.client.netCall = netCall
buildContext.client.deriveSingle = deriveSingle
buildContext.client.deriveList = deriveList
// Custom validators to demonstrate validators in the forms module.
buildContext.validators.required = async ({value}) => {
  if (!!value) {
    return []
  }
  return ['This field is required.']
}
declare type RangeArgs = {
  min?: number,
  max?: number,
}
buildContext.validators.range = async ({value, args}: ValidatorArgs<number, RangeArgs, any>) => {
  if (args.min !== undefined) {
    if (value < args.min) {
      return [`May not be lower than ${args.min}`]
    }
  }
  if (args.max !== undefined) {
    if (value > args.max) {
      return [`May not be greater than ${args.max}`]
    }
  }
  return []
}

// Check out the individual component files to see the examples.

ReactDOM.render(
  <Provider store={store}>
    <ProvidenceContext.Provider value={buildContext}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1>Providence Demo</h1>
            <p>This demo shows off some basic use cases for Providence. Select a tab below for a demonstration of a concept.</p>
          </div>
        </div>
        <TabNav items={[
          {name: 'Singles', component: SinglesDemo},
          {name: 'Lists', component: ListsDemo},
          {name: 'Forms', component: FormsDemo},
        ]} />
      </div>
    </ProvidenceContext.Provider>
  </Provider>,
  // For this example, we're assuming this code will run in an HTML document with a div with an id of 'root'.
  document.getElementById('root'),
);
