import React, {ReactElement} from 'react'
import {useSingle} from '@opencraft/providence/react-plugin'

declare interface TabNavArgs {
  items: Array<{name: string, component: () => ReactElement}>
}

declare interface TabState {
  tab: number,
}

export const TabNav = ({items}: TabNavArgs) => {
  // This is actually an additional example. We use '#' as the endpoint for state we don't intend to send
  // to any server. You could also use the standard useState for this, but it wouldn't be committed to redux.
  const tabController = useSingle<TabState>('tabs', {endpoint: '#', x: {tab: 0}})
  const tab = tabController.p.tab
  const Component = items[tab.model].component
  return (
    <>
      <ul className="nav nav-tabs mb-3">
        {items.map((item, index) => (
          <li className='nav-item' onClick={() => tab.model = index} key={item.name}>
            <button className={`${index === tab.model ? 'active' : ''} nav-link`}>{item.name}</button>
          </li>
        ))}
      </ul>
      <Component />
    </>
  )
}