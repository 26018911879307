import React, {useState} from 'react'
import {Patcher} from '@opencraft/providence/base/singles/types/Patcher'
import {StatusMarker} from './StatusMarker'

declare interface PatchedInputBase {
  patcher: Patcher<any, any>,
  label: string,
  // This will update the field from upstream pushes instantly. You don't want this for text. You do for booleans.
  instant?: boolean,
  id?: string,
}

export const PatchedInput = <T extends PatchedInputBase>({patcher, id, label, instant = false, ...props}: T) => {
  id = id || btoa(patcher.controller.name + patcher.attrName)
  // We create scratch storage here to prevent race conditions that can occur with rapidly updating fields like
  // text inputs. Since we do debounced updates, we could end up with the model updating under us while we're still
  // typing, which would clobber some keystrokes.
  //
  // If this isn't a significant concern (such as with a checkbox or select field) you can set instant to true and get
  // snappier updates that sync immediately with other listeners to the model (in case you have more than one.)
  const [scratch, setScratch] = useState(patcher.model)
  if ((scratch !== patcher.model) && instant) {
    setScratch(patcher.model)
  }
  const updateScratch = (val: typeof patcher.model) => {
    setScratch(val)
    patcher.model = val
  }
  return (
    <div className={'row mb-1'} key={id}>
      <div className={'col-sm-3'}>
        <label htmlFor={id}>{label}: <StatusMarker patcher={patcher} /></label>
      </div>
      <div className={'col-sm-9'}>
        <input id={id} className={'form-control'} value={scratch} {...props} onChange={(event) => updateScratch(event.target.value)}/>
      </div>
    </div>
  )
}