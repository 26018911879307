import {SingleController} from '@opencraft/providence/base/singles/types/SingleController'
import {ListController} from '@opencraft/providence/base/lists/types/ListController'
import {LoadSpinner} from './LoadSpinner'
import React, {ReactElement} from 'react'

declare interface LoadSectionArgs {
  controllers: Array<SingleController<any> | ListController<any>>,
  children: () => ReactElement,
}

export const LoadSection = ({controllers, children}: LoadSectionArgs) => {
  // This component serves as an example wrapper that can defer rendering until any relevant Providence controllers
  // are finished fetching. It will also display any errors once finished. The children of this component should be
  // a function, which will be lazily loaded once all controllers are ready.
  const fetching = !!controllers.filter((controller) => controller.fetching).length
  const ready = controllers.filter((controller) => controller.ready).length === controllers.length
  const errors: string[] = []
  controllers.map((controller) => errors.push(...controller.errors.messages))
  if (fetching) {
    return <LoadSpinner />
  }
  if (ready) {
    const childrenActual = children()
    return <>{childrenActual}</>
  }
  return (
    <div className='row'>
      {errors.map((message) => (
        <div className='col-12'>
          <div className='alert alert-danger' role='alert'>
            {message}
          </div>
        </div>
      ))}
    </div>
  )
}