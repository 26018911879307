import {Patcher} from '@opencraft/providence/base/singles/types/Patcher'

declare interface StatusMarkerArgs {
  patcher: Patcher<any, any>
}

export const StatusMarker = ({patcher}: StatusMarkerArgs) => {
  if (patcher.dirty) {
    return <span>...</span>
  }
  return <span>✅</span>
}