import React, {useState} from 'react'
import {useForm} from '@opencraft/providence/react-plugin'
import {docUrlFor, projectUrlFor} from '../utils'
import Register from '../types/Register'
import {FieldInput} from './FieldInput'
import {BoundForm} from './BoundForm'

export const FormsDemo = () => {
  // Providence exposes controller creation/management through custom React hooks.
  const controller = useForm<Register>('product', {
    endpoint: 'https://reqres.in/api/users',
    fields: {
      email: {value: '', validators: [{name: 'email'}, {name: 'required'}]},
      username: {value: '', validators: [{name: 'required'}]},
      age: {value: 20, validators: [{name: 'range', args: {min: 13, max: 123}}]},
    },
  })
  const [created, setCreated] = useState(false)
  return (
      <div className="row">
        <div className="col-12 pb-2">
          <h2>Form Controllers</h2>
          <p>Form controllers make building consistent, validated forms easy!&nbsp;
            <a href={docUrlFor('module_types/forms.html')}>
              You can read about forms here.
            </a>&nbsp;<a href={projectUrlFor('src/components/FormsDemo.tsx')}>You can view the source code for this part of the
              demo here.</a>
          </p>
          <p>
            Note that as you edit these fields, they are validated. The form is not set up to force validation
            before submission (although you could make a version which does), since your backend should return
            relevant (and potentially more accurate) errors anyway.
          </p>
          <p>To test handling a server error, cut your internet connection and hit submit.</p>
        </div>
        <div className="col-12 pb-2">
          <BoundForm controller={controller} success={() => {setCreated(true)}} preSend={() => setCreated(false)}>
            <FieldInput id="username" fielder={controller.f.username} label="Username" />
            <FieldInput id="email" fielder={controller.f.email} label="Email" />
            <FieldInput id="age" fielder={controller.f.age} label="Age" type="number" step="1" />
            <button type="submit" disabled={controller.sending}>Submit</button>
          </BoundForm>
        </div>
        <div className="col-12">
          {created && <div className="alert alert-success">Created!</div>}
        </div>
        {controller.errors.map((error) => (
          <div className="col-12 pb-2" key={error}>
            <div className="alert alert-danger">{error}</div>
          </div>
        ))}
      </div>
  )
}