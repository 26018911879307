import React from 'react'
import {useList} from '@opencraft/providence/react-plugin'
import {Product} from '../types/Product'
import {PatchedInput} from './PatchedInput'
import {Paginated} from './Paginated'
import {docUrlFor, projectUrlFor} from '../utils'

export const ListsDemo = () => {
  const controller = useList<Product>('productList', {endpoint: 'https://reqres.in/api/products/'})
  controller.getOnce()
  return (
    <div className="row">
      <div className='xs12'>
        <h2>List Demo</h2>
        <p>
          This demonstrates the list handling functionality of Providence. Lists are composed of multiple Singles.&nbsp;
          <a href={docUrlFor('module_types/lists.html')}>
            You can read about lists here.
          </a> We grab from a paginated endpoint all the
          entries it holds and render them all-- with fields built atop&nbsp;<a href={docUrlFor('module_types/singles.html#patchers')}>patchers</a>! Note that if you delete or modify an item,
          and then change pages, the item will return when you go back to the previous page. This is not a bug in
          Providence, but a limitation of the dummy API we're using.
        </p>
        <p><a href={projectUrlFor('src/components/ListsDemo.tsx')}>You can view the source code for this demo here.</a></p>
      </div>
      <Paginated controller={controller}>
        {controller.list.map((singleController) => (
          <div className='col-12 col-md-4 mb-2' key={singleController.x!.id}>
            <div className='card'>
              <h3>ID: {singleController.x!.id}</h3>
              <PatchedInput patcher={singleController.p.name} label='Name'/>
              <PatchedInput patcher={singleController.p.color} label='Color' type='color'/>
              <PatchedInput patcher={singleController.p.year} label='Year'/>
              <button className='btn btn-danger' onClick={singleController.delete}>Delete</button>
            </div>
          </div>
        ))}
      </Paginated>
    </div>
  )
}