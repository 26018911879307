import React from 'react'
import {Fielder} from '../../../src/base/forms/types/Fielder'

declare interface FieldInputBase<T> {
  fielder: Fielder<T, keyof T>,
  label: string,
  id?: string,
}

export const FieldInput = <T,>({fielder, id, label, ...props}: FieldInputBase<T>) => {
  id = id || btoa(fielder.controller.name + fielder.fieldName)
  const disabled = fielder.disabled || fielder.controller.sending
  let className = 'form-control'
  if (fielder.errors.length) {
    className += ' is-invalid'
  }
  return (
    <div className={'row mb-1'} key={id}>
      <div className={'col-sm-3'}>
        <label htmlFor={id} className={'form-label'}>{label}</label>
      </div>
      <div className={'col-sm-9'}>
        <div className={'input-group has-validation'}>
          <input
            id={id}
            className={className}
            value={fielder.model as any}
            disabled={disabled} {...props}
            onChange={(event) => (fielder.model = event.target.value as any)}
            onBlur={fielder.validate}
            aria-describedby={`${id}-feedback`}
          />
        </div>
        {fielder.errors && (
          <div className="invalid-feedback" id={`${id}-feedback`}>
            {fielder.errors[0]}
          </div>
        )}
      </div>
    </div>
  )
}