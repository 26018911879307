import React from 'react'

export const LoadSpinner = () => {
  return (
    <div className='row'>
      <div className='col-12 text-center'>
        <div className="logo-character App-logo d-inline-block pa-0" style={{lineHeight: '1'}}>✇</div>
      </div>
    </div>
  )
}