import React from 'react'
import {ReactNode} from 'react'
import {ListController} from '@opencraft/providence/base/lists/types/ListController'
import {LoadSection} from './LoadSection'

declare interface PaginatedArgs<T> {
  children: ReactNode,
  controller: ListController<T>,
}

declare interface PageNumbersArgs<T> {
  controller: ListController<T>
}

const PageNumbers = <T,>({controller}: PageNumbersArgs<T>) => {
  if (!controller.totalPages || controller.totalPages === 1) {
    return <></>
  }
  const pages: number[] = []
  for (let i = 1; i <= controller.totalPages; i++) {
    pages.push(i)
  }
  const getPage = (pageNum: number) => {
    if (pageNum === controller.currentPage) {
      return
    }
    controller.setPage(pageNum)
    controller.get().catch()
  }
  const getPrev = () => {
    if (controller.currentPage === 1) {
      return
    }
    getPage(controller.currentPage - 1)
  }
  const getNext = () => {
    if (controller.currentPage === controller.totalPages) {
      return
    }
    getPage(controller.currentPage + 1)
  }
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className="page-item" onClick={getPrev}><button className="page-link">Previous</button></li>
        {pages.map((index) => (
          <li className="page-item" onClick={() => getPage(index)} key={index}>
            <button className="page-link">{index}</button>
          </li>
        ))}
        <li className="page-item" onClick={getNext}><button className="page-link">Next</button></li>
      </ul>
    </nav>
  )
}

export const Paginated = <T,>(props: PaginatedArgs<T>) => {
  return (
    <>
      <PageNumbers controller={props.controller} />
      <LoadSection controllers={[props.controller]}>{() => (
        <div className='row'>{props.children}</div>
      )}
      </LoadSection>
      <PageNumbers controller={props.controller} />
    </>
  )
}