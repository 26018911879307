import {FormController} from '../../../src/base/forms/types/FormController'
import {FormEvent, ReactNode} from 'react'

declare interface BoundFormArgs {
  controller: FormController<any>,
  success: () => any,
  preSend: () => any,
  children: ReactNode,
}

export const BoundForm = ({children, controller, preSend, success}: BoundFormArgs) => {
  const submitter = (event: FormEvent) => {
    event.preventDefault()
    preSend()
    controller.submit().then(success, controller.handleError)
  }
  return <form onSubmit={submitter} className="needs-validation">{children}</form>
}