import React from 'react'
import {useSingle} from '@opencraft/providence/react-plugin'
import {ColorDisplay} from './ColorDisplay'
import {PatchedInput} from './PatchedInput'
import {StatusMarker} from './StatusMarker'
import {Product} from '../types/Product'
import {LoadSection} from './LoadSection'
import {docUrlFor, projectUrlFor} from '../utils'

export const SinglesDemo = () => {
  // Providence exposes controller creation/management through custom React hooks.
  const controller = useSingle<Product>('product', {endpoint: 'https://reqres.in/api/products/3'})
  controller.getOnce()
  return (
    <LoadSection controllers={[controller]}>{() => (
      <div className="row">
        <div className="col-12 pb-2">
          <h2>Single Controllers</h2>
          <p>Single controllers are the basic building blocks of Providence's state management.&nbsp;
            <a href={docUrlFor('module_types/singles.html')}>
              You can read about singles here
            </a>.&nbsp;<a href={projectUrlFor('src/components/SinglesDemo.tsx')}>You can view the source code for this part of the
              demo here.</a>
          </p>
        </div>
        <div className="col-12">
          <h3>Controller-based requests</h3>
          <div>
            <p>This section uses
              the&nbsp;<a href={docUrlFor('module_types/singles.html#SingleController.patch')}>patch function</a>&nbsp;of
              the&nbsp;<a href={docUrlFor('concepts.html#controllers')}>controller</a>&nbsp;to
              update the data. Clicking the button several times will
              only ever increase the year by one from its last known good point, since sending a patch request
              cancels any old ones and resends.
            </p>
            <h3>Product name: "{controller.x!.name}"</h3>
            <div>Year: {controller.x!.year}</div>
            <div>Color: <ColorDisplay color={controller.x!.color}/></div>
            <button className="btn btn-primary"
                    onClick={() => controller.patch({year: controller.x!.year + 1})}>Set year to {controller.x!.year + 1}
            </button>
          </div>
          <hr/>
        </div>
        <div className="col-12">
          <h2>Patcher-based updates</h2>
          <p>
            This section
            uses&nbsp;<a href={docUrlFor('module_types/singles.html#patchers')}>patchers</a>&nbsp;to
            manage the data. The patcher will live-update while caching the changes you're
            making, committing them to the server in a debounced way. In most cases, this is what you really want.
          </p>
          <p>Each of these fields is built with an example
            a&nbsp;<a href={projectUrlFor('src/components/PatchedInput.tsx')}>lightweight component</a>&nbsp;that leverages
            the patcher to make a consistent, expressive form field.</p>
          <div>
            <h3>
              <PatchedInput label="Product Name" patcher={controller.p.name}/>
            </h3>
            <div className={'row mb-1'}>
              <div className={'col-sm-3'}>
                Year: {controller.p.year.model}
                <StatusMarker patcher={controller.p.year}/>
              </div>
              <div className={'col-sm-9'}>
                <button className="btn btn-primary" onClick={() => controller.p.year.model += 1}>Increase year</button>
              </div>
            </div>
            <div><PatchedInput patcher={controller.p.color} label="Color" type="color"/></div>
          </div>
          <p>
            Note that both copies use the same controller and handle the same slice under the hood. This means they will
            always eventually become consistent.
          </p>
        </div>
      </div>)}
    </LoadSection>
  )
}